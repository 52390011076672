<template>
  <div id="AboutUs">
    <!-- AboutUs首页头部 -->
    <div class="AboutUsHeader">
      <!-- 头部组件 -->
      <Header></Header>
    </div>
    <!-- AboutUs中间内容 -->
    <div class="AboutUsCenter">
         <!-- 浙江三象数据有限公司 -->
      <div class="dtsum">
        <img src="~assets/img/AboutUs/公司照片@2x.png" alt="" />
        <div class="dtsumProduce">
          <div class="dtsumName">浙江三象数据有限公司</div>
          <div class="dtsumDetail">
            公司位于杭州市临平区中国工业互联网小镇，为杭州市重点引进海内外高层次人才创
            业项目，依托产业集群，下设舟山群岛新区、宁波、诸暨、东阳4家工业信息工程服务子公司。
          </div>
          <div class="dtsumSlogan">
            公司始终坚持以“挖掘看不见世界中的价值”为使命，通过信息技术、大数据驱动为工业转型升级赋能。
          </div>
          <div class="dtsumText">
            公司面向工业企业提供数字化工厂解决方案，打通构建人、机、料、法、环智慧互联
            的企业”内链”，同时汇聚行业生产要素数据，建设工业互联网平台，通过数据的分
            析、应用，打通产业链上下游网络化共享、智慧化协作的“外链”，促进行业融通发
            展。
          </div>
        </div>
      </div>
      <!-- 公司活动 -->
      <div class="companyEvent">
        <div class="companyEvents">公司活动</div>
        <ul class="comEventImg">
          <li v-for="(item, index) in comEventImg" :key="index">
            <img :src="item.imgs" alt="" />
          </li>
        </ul>
      </div>
      <!-- 公司资质 -->
      <div class="dtsumCertificate">
          <div class="dtsumCerTit">公司资质</div>
          <div class="btns">
          <div  @click="change1"  :class="flag===1?'btn1':''" class="btnn">荣誉资质</div>
          <div @click="change2"  :class="flag===2?'btn1':''" class="btnn">知识产权</div>
          </div>
           <!--  <el-button type="primary" plain class="btnn" @click="change1">荣誉资质</el-button>
            <el-button type="primary" plain class="btnn" @click="change2">知识产权</el-button> -->
          <!--   <div class="ImgCon" v-if="flag == 1">
                <img src="~assets/img/AboutUs/1.软件高质量发展.png" id="imOne">
                <a href=" #imOne"><img src="~assets/img/AboutUs/2.工业信息工程.png"></a>
                <img src="~assets/img/AboutUs/3.人才创新创业项目.png">

                <a href=" #imFive"><img src="~assets/img/AboutUs/4.企业研发中心.png"></a>
                <img src="~assets/img/AboutUs/5.优秀工业APP.png" id="imFive">
            </div>
            <div class="ImgCon" v-if="flag == 2">
               <a href=" #imOne"> <img src="~assets/img/AboutUs/三象设备停机监控.png" id="imOne"></a>
                <img src="~assets/img/AboutUs/象来紧固件软著受理.png">
                <img src="~assets/img/AboutUs/象来专利受理.png">
                <a href=" #imFour"><img src="~assets/img/AboutUs/信息化管理.png" id="imFour"></a>
            </div> -->
    <!--    <Swiper>
       </Swiper> -->

  <div class="ImgCon" v-if="flag == 1">
    <el-carousel :interval="4000" type="card" height="325px">
    <el-carousel-item v-for="(item,index) in SwiperImgs1" :key="index">
       <img :src="item.imgs" alt="" >
       <div class="textPro">{{item.text}}</div>
    </el-carousel-item>
  </el-carousel>
  </div>

    <div class="ImgCon" v-if="flag == 2">
    <el-carousel :interval="4000" type="card" height="325px">
    <el-carousel-item v-for="(item,index) in SwiperImgs2" :key="index">
       <img :src="item.imgs" alt="" >
       <div class="textPro">{{item.text}}</div>
    </el-carousel-item>
  </el-carousel>
  </div>
      </div>
    </div>
    <Footer />
      <!-- 回到顶部 -->
    <el-backtop
      ><img
        src="~assets/img/AboutUs/icon-一键置顶@2x.png"
        alt="icon-回到顶部@2x.png"
    /></el-backtop>
  </div>
</template>
<script>
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
// import Swiper from "components/Swiper/Swiper";
export default {
  name: "IOTPlatform",
  data() {
    return {
      comEventImg: [
        { imgs: require("assets/img/AboutUs/公司党支部党建活动@2x.png") },
        {
          imgs: require("assets/img/AboutUs/阿里云SupET创新中心一周年大会@2x.png")
        },
        { imgs: require("assets/img/AboutUs/大会议室@2x.png") }
      ],
      SwiperImgs1: [
        { imgs: require("assets/img/AboutUs/1.软件高质量发展.png"), text: '浙江省软件高质量发展重点项目' },
        { imgs: require("assets/img/AboutUs/2.工业信息工程.png"), text: '浙江省工业信息工程服务机构' },
        { imgs: require("assets/img/AboutUs/3.人才创新创业项目.png"), text: '余杭区海内外高层次人才创新创业项目' },
        { imgs: require("assets/img/AboutUs/4.企业研发中心.png"), text: '企业研发中心' },
        { imgs: require("assets/img/AboutUs/5.优秀工业APP.png"), text: '2020年工信部优秀工业APP项目' },
      ],
      SwiperImgs2: [
        { imgs: require("assets/img/AboutUs/三象设备停机监控.png"), text: '三象设备停机监控系统' },
        { imgs: require("assets/img/AboutUs/象来紧固件软著受理.png"), text: '一种同时检测纺织纱线断纱和预警装置' },
        { imgs: require("assets/img/AboutUs/象来专利受理.png"), text: '优云织造行业生产执行系统' },
        { imgs: require("assets/img/AboutUs/信息化管理.png"), text: '信息化管理项目系统' },
      ],
     
      flag: 1,
      

    };
  },
   methods: {
            change1: function() {
                this.flag = 1
            },
            change2: function() {
                this.flag = 2
            },

  },
  components: {
    Header,
    Footer,
    // Swiper
  }
};
</script>
<style lang="less" >
#AboutUs {
   width: 1583px;
  background-color: #f8f9fb;
  margin: 0 auto;
  // AboutUs头部样式
  .AboutUsHeader {
    width: 1583px;
    height: 620px;
    background: url("~assets/img/AboutUs/banner@2x.png") no-repeat;
    background-size: 1583px 620px;
    background-position: center;
    margin: 0 auto;
  }
  // AboutUs中间内容
  .AboutUsCenter {
    width: 100%;
    margin: 80px auto 45px;
    // 浙江三象数据有限公司
    .dtsum {
      width: 1220px;
      margin: 0 auto;
      display: flex;
      img {
        width: 546px;
        height: 428px;
      }
      .dtsumProduce {
        margin-left: 40px;
        .dtsumName {
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #333333;
          margin-top: 46px;
        }
        .dtsumDetail {
          width: 610px;
          height: 72px;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 28px;
          color: rgba(51, 51, 51, 0.85);
          margin-top: 40px;
        }
        .dtsumSlogan {
          width: 610px;
          height: 44px;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          line-height: 28px;
          color: #1e90ff;
          margin-top: 30px;
        }
        .dtsumText {
          width: 610px;
          height: 100px;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 28px;
          color: rgba(51, 51, 51, 0.85);
          margin-top: 30px;
        }
      }
    }
    // 公司活动
    .companyEvent {
       width: 1220px;
      margin: 60px auto 0;
      .companyEvents {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #333333;
      }
      .comEventImg {
        width: 1220px;
        height: 243px;
        padding: 0;
        display: flex;
        justify-content: space-between;
        li {
          &:nth-child(1) {
            img {
              width: 431px;
              height: 243px;
            }
          }
          &:nth-child(2) {
            img {
              width: 364px;
              height: 243px;
            }
          }
          &:nth-child(3) {
            img {
              width: 364px;
              height: 243px;
            }
          }
        }
      }
    }
// 公司资质
.dtsumCertificate{
margin: 80px auto;
    text-align: center;
    .dtsumCerTit{
font-size: 20px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #333333;
opacity: 1;
    }
/*     .btnn{
    width: 124px;
height: 40px;
border-radius: 6px;
display: inline-block;
margin: 30px 100px 0 ;
cursor: pointer;
font-size: 16px;
font-family: Source Han Sans CN;
font-weight: 400;
color: #080808;
text-align: center;
border: none;
} */
.btns{
width: 449px;
height: 40px;
margin: 30px auto 0;
display: flex;
justify-content: space-between;
}
.btnn{
  width: 124px;
  height: 40px;
  cursor: pointer;
  line-height: 40px;
}
.btn1{
  width: 124px;
  height: 40px;
  cursor: pointer;
  line-height: 40px;
  color: #fff;
background: #1E90FF;
border-radius: 6px;

}
.textPro{
font-size: 16px;
font-family: Source Han Sans CN;
font-weight: 400;
color: rgba(51, 51, 51, 0.85);
}
   
}
  }
}

.el-backtop{
    img{
        width: 30px;
        height: 30px;
    }
}
.el-button--primary{
  background: transparent;
}
</style>
